import { StringParam } from 'use-query-params'

export const QUARTER_RANGE_START_QUERY_PARAM = {
    name: 'start-quarter',
    type: StringParam,
}

export const QUARTER_RANGE_END_QUERY_PARAM = {
    name: 'end-quarter',
    type: StringParam,
}
