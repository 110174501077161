import { DateRangePicker } from '@/components/base'
import { useInputState } from '@/hooks'
import { dateToMonthInd, monthIndToDateNoTimezoneReset } from '@/utils/date/monthInd'

import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM } from './MonthRangeInput.constants'
import { MonthRangeInputProps } from './MonthRangeInput.types'

export const MonthRangeInput = (props: MonthRangeInputProps) => {
    const { defaultStart, defaultEnd, maxDate, required, label, startRangeInputParams, endRangeInputParams } = props
    const [startParam, setStartParam] = useInputState({ ...MONTH_RANGE_START_QUERY_PARAM, ...startRangeInputParams })
    const [endParam, setEndParam] = useInputState({ ...MONTH_RANGE_END_QUERY_PARAM, ...endRangeInputParams })

    if (!startParam && defaultStart) {
        setStartParam(defaultStart)
    }

    if (!endParam && defaultEnd) {
        setEndParam(defaultEnd)
    }

    const dateFrom = startParam ? monthIndToDateNoTimezoneReset(startParam) : undefined
    const dateTo = endParam ? monthIndToDateNoTimezoneReset(endParam) : undefined

    return (
        <DateRangePicker
            type='month'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            onChange={({ dateFrom, dateTo }) => {
                setStartParam(dateFrom ? dateToMonthInd(dateFrom) : undefined)
                setEndParam(dateTo ? dateToMonthInd(dateTo) : undefined)
            }}
            error={required && (!dateFrom || !dateTo)}
        />
    )
}
