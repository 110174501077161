import { useEffect } from 'react'

import { QueryParamConfig, useQueryParam } from 'use-query-params'

import { useLocalStorage, UseLocalStorageOptions, useRouteKey, useTabStorage, UseTabStorageOptions } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'

export interface UseInputStateParams<T> {
    name: string
    type: QueryParamConfig<T, T>
    syncLocalStorage?: boolean
    /**
     * this hook is not compatible with withDefaults provided by use-query-params
     */
    defaultValue?: T
    /**
     * Options for 'use-local-storage' library
     */
    localStorageOptions?: UseLocalStorageOptions<T>
    /**
     * Recover values by key from local storage scoped by tab id
     */
    syncTabStorage?: boolean
    /**
     * Custom key for tab storage. If not provided, will use default pattern:
     * ${companyId}:${routeKey}:${name}:tab
     */
    tabStorageKey?: string
    /**
     * Options for useTabStorage hook
     */
    tabStorageOptions?: UseTabStorageOptions<T>
}

/**
 * Hook to manage input state and sync it with query params and local storage
 *
 * @todo Parse Date valuef from local storage here
 *  now you need to use localStorageOptions: {parser: parseJSON } every time with DateParam
 */
export const useInputState = <T = unknown>(params: UseInputStateParams<T>) => {
    const {
        name,
        type,
        syncLocalStorage = false,
        syncTabStorage = false,
        tabStorageKey: customTabKey,
        localStorageOptions,
        tabStorageOptions,
        defaultValue,
    } = params

    const { me } = useMe()
    const routeKey = useRouteKey()

    const localStorageKey = `${me?.companyUser?.company_id}:${routeKey || ''}:${name}`
    const tabStorageKey = customTabKey || localStorageKey

    const [valueInLocalStorage, setValueInLocalStorage] = useLocalStorage<T | undefined>(localStorageKey, undefined, localStorageOptions)
    const [valueInTabStorage, setValueInTabStorage] = useTabStorage<T | undefined>(tabStorageKey, undefined, tabStorageOptions)
    const [value, setValue] = useQueryParam<T>(name, type)

    const shouldUseValueFromLocalStorage = !value && valueInLocalStorage && syncLocalStorage
    const shouldUseValueFromTabStorage = !value && valueInTabStorage && syncTabStorage

    useEffect(() => {
        if (!shouldUseValueFromLocalStorage && !shouldUseValueFromTabStorage && !value && defaultValue) {
            setValue(defaultValue)
        }
    }, [shouldUseValueFromLocalStorage, shouldUseValueFromTabStorage, value, defaultValue, setValue])

    useEffect(() => {
        if (shouldUseValueFromLocalStorage) {
            setValue(valueInLocalStorage)
        } else if (shouldUseValueFromTabStorage) {
            setValue(valueInTabStorage)
        }
    }, [])

    useEffect(() => {
        if (syncLocalStorage) {
            setValueInLocalStorage(value)
        } else if (syncTabStorage) {
            setValueInTabStorage(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setValueInLocalStorage,
        setValueInTabStorage,
        syncLocalStorage,
        syncTabStorage,
        JSON.stringify(value),
    ])

    return [
        // to avoid undefined on the 1st render return value from local storage
        shouldUseValueFromLocalStorage ? valueInLocalStorage
            : shouldUseValueFromTabStorage ? valueInTabStorage
                : value || defaultValue,
        setValue,
    ] as const
}
