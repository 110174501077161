import { useEffect } from 'react'

import { CompanyUserMode } from '@/core/modes'
import {
    ADMIN_ROUTES_CONFIG,
    MANAGER_ROUTES_CONFIG,
    OWNER_ROUTES_CONFIG, RouterConfigKeys, RoutesConfigValue,
} from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import { useRouteKey } from '@/hooks/useRouteKey'
import { mixpanelTrackPage } from '@/utils/mixpanel'

const DEFAULT_TITLE = 'Intelas'

export const ROUTES_BY_MODE: Record<CompanyUserMode, Record<string, RoutesConfigValue>> = {
    [CompanyUserMode.Admin]: ADMIN_ROUTES_CONFIG,
    [CompanyUserMode.Owner]: OWNER_ROUTES_CONFIG,
    [CompanyUserMode.Manager]: MANAGER_ROUTES_CONFIG,
}

let latPathNameHandled = ''

export const setPageTitle = (titlesString: string) => {
    document.title = titlesString
    // Exclude duplicates
    if (window.location.pathname !== latPathNameHandled) {
        mixpanelTrackPage(titlesString)
        latPathNameHandled = window.location.pathname
    }
}

export const getTrackingTitle = (companyUserMode: CompanyUserMode, routeKey?: RouterConfigKeys, extraSegment?: string): string => {
    const routeConfig = ROUTES_BY_MODE[companyUserMode]

    const titleFound = Boolean(routeKey && routeConfig[routeKey]?.title)

    // Get the main route title
    const mainRouteTitle = titleFound && routeKey ? routeConfig[routeKey].title : DEFAULT_TITLE

    // Collect titles chain based on current route
    const titlesString = titleFound && routeKey
        ? Object.values(routeConfig)
            .filter(route => routeConfig[routeKey].path.startsWith(route.path))
            .sort((a, b) => a.path.length - b.path.length)
            .map(route => route.title)
            .concat(extraSegment || '')
            .filter(Boolean)
            .join(' - ')
        : mainRouteTitle

    return titlesString
}

// These keys will be set individually
const IGNORE_KEYS: RouterConfigKeys[] = [
    'REPORTS_CUSTOM',
    'TABLE_BUILDER',
]

export const useSetPageTitlteTag = () => {
    const routeKey = useRouteKey()
    const { me: { companyUserMode } } = useMe()

    const titlesString = getTrackingTitle(companyUserMode, routeKey)

    useEffect(() => {
        if (IGNORE_KEYS.includes(routeKey as RouterConfigKeys)) return

        setPageTitle(titlesString)
    }, [titlesString])
}
