import { useEffect, useMemo, useRef, useState } from 'react'

type Serializer<T> = (object: T | undefined) => string
type Parser<T> = (val: string) => T | undefined
type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>

type Options<T> = Partial<{
    serializer: Serializer<T>
    parser: Parser<T>
    logger: (error: any) => void
}>

export type UseTabStorageOptions<T> = Options<T>

export function useTabStorage<T> (
    key: string,
    defaultValue: T,
    options?: Options<T>
): [T, Setter<T>]

export function useTabStorage<T> (
    key: string,
    defaultValue?: T,
    options?: Options<T>,
) {
    const opts = useMemo(() => {
        return {
            serializer: JSON.stringify,
            parser: JSON.parse,
            // eslint-disable-next-line no-console
            logger: console.log,
            ...options,
        }
    }, [options])

    const { serializer, parser, logger } = opts

    const rawValueRef = useRef<string | null>(null)

    const [value, setValue] = useState<T>(() => {
        if (typeof window === 'undefined') return defaultValue as T

        try {
            rawValueRef.current = window.sessionStorage.getItem(key)
            const res: T = rawValueRef.current
                ? parser(rawValueRef.current)
                : defaultValue as T
            return res
        } catch (e) {
            logger(e)
            return defaultValue as T
        }
    })

    useEffect(() => {
        if (typeof window === 'undefined') return

        const updateSessionStorage = () => {
            if (value !== undefined) {
                const newValue = serializer(value)
                rawValueRef.current = newValue
                window.sessionStorage.setItem(key, newValue)
            } else {
                window.sessionStorage.removeItem(key)
            }
        }

        try {
            updateSessionStorage()
        } catch (e) {
            logger(e)
        }
    }, [value])

    return [value, setValue] as [T, Setter<T>]
}
