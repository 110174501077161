import { DateRangePicker } from '@/components/base/DateRangePicker'
import { useInputState } from '@/hooks'
import { dateToQuarter, QuarterString, quarterToDate } from '@/utils/date/quarter'

import { QUARTER_RANGE_END_QUERY_PARAM, QUARTER_RANGE_START_QUERY_PARAM } from './QuarterRangeInput.constants'
import { QuarterRangeInputProps } from './QuarterRangeInput.types'

export const QuarterRangeInput = (props: QuarterRangeInputProps) => {
    const { defaultStart, defaultEnd, maxDate, required, label, syncLocalStorage } = props

    const [startParam, setStartParam] = useInputState({ ...QUARTER_RANGE_START_QUERY_PARAM, syncLocalStorage })
    const [endParam, setEndParam] = useInputState({ ...QUARTER_RANGE_END_QUERY_PARAM, syncLocalStorage })

    if (!startParam && defaultStart) {
        setStartParam(dateToQuarter(defaultStart))
    }

    if (!endParam && defaultEnd) {
        setEndParam(dateToQuarter(defaultEnd))
    }

    const dateFrom = startParam ? quarterToDate(startParam as QuarterString) : undefined
    const dateTo = endParam ? quarterToDate(endParam as QuarterString) : undefined

    return (
        <DateRangePicker
            type='quarter'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            onChange={({ dateFrom, dateTo }) => {
                setStartParam(dateFrom ? dateToQuarter(dateFrom) : undefined)
                setEndParam(dateTo ? dateToQuarter(dateTo) : undefined)
            }}
            error={required && (!dateFrom || !dateTo)}
        />
    )
}
